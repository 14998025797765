import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setReloadFalse, signIn } from './redux/actions';
import Layout from './layouts/Layout';
import { useCookies } from 'react-cookie';
import { useQuery } from '@apollo/client';
import { LOGGED_USER } from './GraphQL/Queries';
import Loader from './components/common/Loader';
import { client } from './helpers/apolloClient';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

const App = ({ signIn, setReloadFalse, reloadData }) => {
  const [cookies] = useCookies(['AUTH_TOKEN', 'USER_ID']);
  const [userId, setUserId] = useState(null);

  const LoadedUser = useQuery(LOGGED_USER, {
    variables: {
      id: userId,
      skip: !userId
    }
  });

  useEffect(() => {
    if (reloadData) {
      console.log('cleared cache');
      client.resetStore();
      setReloadFalse();
    }
  }, [reloadData, setReloadFalse]);

  useEffect(() => {
    if (cookies.AUTH_TOKEN) {
      if (cookies.USER_ID) {
        setUserId(cookies.USER_ID);
        if (userId && LoadedUser.data && !LoadedUser.loading && LoadedUser.data?.user?.id) {
          signIn({
            userId: LoadedUser.data.user.id,
            email: LoadedUser.data.user.email,
            fullName: `${LoadedUser.data.user.name} ${LoadedUser.data.user.surname}`,
            roles: LoadedUser.data.user.roles,
            clientPerson: LoadedUser.data.user.clientPerson
              ? LoadedUser.data.user.clientPerson.id
              : null,
            tmbPerson: LoadedUser.data.user.tmbPerson ? LoadedUser.data.user.tmbPerson.id : null,
            profileImage: LoadedUser.data.user.avatar || null
          });
        }
      }
    }
  }, [cookies.AUTH_TOKEN, cookies.USER_ID, userId, LoadedUser.data, LoadedUser.loading, signIn]);

  return (
    <>
      {LoadedUser.loading ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: '100vh', width: '100vw' }}
        >
          <Loader />
          <h5 className="text-center">Ładowanie aplikacji...</h5>
        </div>
      ) : (
        <Layout />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reloadData: state.reloadData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (user) => dispatch(signIn(user)),
    setReloadFalse: () => dispatch(setReloadFalse())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
