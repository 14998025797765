import { gql } from '@apollo/client';

export const LOGGED_USER = gql`
  query LoadUserInfo($id: ID!) {
    user(id: $id) {
      id
      email
      roles
      name
      surname
      tmbPerson {
        id
      }
      clientPerson {
        id
      }
      avatar
    }
  }
`;

export const LOAD_ORGANIZATIONS = gql`
  query {
    organizations {
      id
      name
      nip
      tmbPerson {
        id
        user {
          name
          surname
        }
      }
      phone
      email
      website
      town
      country
      postCode
      address
      status
      voivodeship
      description
      shortName
      departments {
        id
        name
      }
    }
  }
`;
export const LOAD_ORGANIZATION = gql`
  query LoadOrganization($id: ID!) {
    organization(id: $id) {
      id
      name
      shortName
      nip
      address
      postCode
      town
      voivodeship
      country
      description
      phone
      email
      website
      hasRecipient
      recStreet
      recTown
      recPostCode
      recBuildingNumber
      recLocalNumber
      recName
      debt
      isVip
      tmbPerson {
        id
        user {
          name
          surname
        }
      }
      status
      departments {
        id
        name
      }
    }
  }
`;
export const LOAD_ORGANIZATION_DEPARTMENTS = gql`
  query LoadOrganizationDepartments($id: ID!) {
    organization(id: $id) {
      departments {
        id
        name
        town
        postCode
        street
        buildingNumber
        localNumber
        devices {
          id
        }
      }
    }
  }
`;
export const LOAD_ORGANIZATION_PERSONS = gql`
  query LoadOrganizationPersons($id: ID!) {
    organization(id: $id) {
      clientPeople {
        id
        position
        birthdate
        nameday
        dataConsent
        marketingConsent
        Department {
          id
          name
        }
        user {
          id
          name
          surname
          email
        }
        devices {
          id
        }
      }
    }
  }
`;

export const LOAD_DEVICES_ADD_TICKET_MODAL = gql`
  query {
    devices {
      id
      serialNumber
      clientPerson {
        id
      }
      Department {
        id
        name
        organization {
          id
          name
        }
      }
    }
  }
`;

export const LOAD_ORGANIZATION_DEVICES = gql`
  query LoadOrganization($id: ID!) {
    organization(id: $id) {
      departments {
        devices {
          _id
          id
          serialNumber
          isActive
          Department {
            id
            name
            organization {
              id
              name
            }
          }
          warehausProduct {
            id
            model
            isColour
            avatar
            manufacturer {
              id
              name
            }
          }
          clientPerson {
            id
          }
          tickets {
            id
            status
            label
          }
        }
      }
    }
  }
`;

export const LOAD_DEVICE = gql`
  query LoadDevice($id: ID!) {
    device(id: $id) {
      _id
      id
      serialNumber
      isActive
      mfp
      a3
      warrantyExpiryDate
      nextVisitDate
      frequencyOfWarrantyServices
      warrantyExpiryDate
      includeInstruction
      includeCD
      includePowerCable
      includeCableRJ
      clientPerson {
        id
        user {
          id
          name
          surname
        }
      }
      Department {
        id
        name
        organization {
          id
          name
          clientPeople {
            id
            user {
              id
              name
              surname
            }
          }
        }
      }
      warehausProduct {
        id
        model
        isColour
        avatar
        manufacturer {
          id
          name
        }
        productType {
          id
        }
        familyTypes {
          id
        }
      }
      tickets {
        id
        status
        label
      }
      deviceHistories {
        id
        createdAt
        counterScan
        counterMono
        counterColor
        source
        tmbPerson {
          id
          user {
            id
            name
            surname
          }
        }
      }
      agreements {
        id
        type
        label
        status
        bwPagePack
        costPageBw
        attachment
        scanPagePack
        costPageScan
        colorPagePack
        costPageColor
        subscriptionFee
        AgreementStartDate
        AgreementExpirationDate
        warehouseProductCopy
      }
    }
  }
`;

export const LOAD_DEVICE_AGREEMENTS_DATA = gql`
  query LoadDevice($id: ID!) {
    device(id: $id) {
      id
      agreements {
        id
        type
        label
        status
        bwPagePack
        costPageBw
        attachment
        scanPagePack
        costPageScan
        colorPagePack
        costPageColor
        subscriptionFee
        AgreementStartDate
        AgreementExpirationDate
        warehouseProductCopy
      }
    }
  }
`;

export const LOAD_DEVICE_WAREHOUSE_PRODUCT_COPY = gql`
  query LoadDevice($id: ID!) {
    device(id: $id) {
      id
      agreements {
        warehouseProductCopy
      }
    }
  }
`;

export const LOAD_MANUFACTURERS = gql`
  query {
    manufacturers {
      id
      name
    }
  }
`;
export const LOAD_WAREHAUSPRODUCTS = gql`
  query {
    warehausProducts {
      id
      model
      manufacturer {
        id
      }
      productType {
        id
      }
      familyTypes {
        id
      }
      performance
    }
  }
`;
export const LOAD_DEPARTMENTS = gql`
  query {
    departments {
      id
      name
      street
      postCode
      town
      buildingNumber
      localNumber
      organization {
        id
      }
    }
  }
`;
export const LOAD_DEPARTMENT = gql`
  query LoadDepartment($id: ID!) {
    department(id: $id) {
      id
      name
      street
      postCode
      town
      buildingNumber
      localNumber
      organization {
        id
        name
      }
      devices {
        _id
        id
        serialNumber
        isActive
        Department {
          id
          name
          organization {
            id
            name
          }
        }
        warehausProduct {
          id
          model
          isColour
          manufacturer {
            id
            name
          }
        }
        clientPerson {
          id
        }
        tickets {
          id
          status
          label
        }
      }
    }
  }
`;
export const LOAD_DEPARTMENT_PERSONS = gql`
  query LoadDepartmentPersons($id: ID!) {
    department(id: $id) {
      clientPeople {
        id
        position
        birthdate
        nameday
        dataConsent
        marketingConsent
        Department {
          id
          name
        }
        user {
          id
          name
          surname
          email
        }
        devices {
          id
        }
      }
    }
  }
`;
export const LOAD_USERS = gql`
  query {
    users {
      id
      name
      email
      roles
      phone
      mobile
      symbol
      surname
      website
      tmbPerson {
        id
      }
      clientPerson {
        id
        nameday
        position
        birthdate
        dataConsent
        marketingConsent
        clientPersonRole
        Department {
          id
          name
        }
        devices {
          id
        }
      }
    }
  }
`;
export const LOAD_USER = gql`
  query LoadUser($id: ID!) {
    user(id: $id) {
      id
      name
      email
      roles
      phone
      mobile
      symbol
      surname
      website
      avatar
      tmbPerson {
        id
        colour
      }
      clientPerson {
        id
        nameday
        position
        birthdate
        dataConsent
        marketingConsent
        clientPersonRole
        Department {
          id
          name
        }
        Organization {
          id
          name
          isVip
        }
      }
    }
  }
`;
export const LOAD_CLIENT_PERSON = gql`
  query LoadClientPerson($id: ID!) {
    clientPerson(id: $id) {
      id
      position
      birthdate
      nameday
      dataConsent
      marketingConsent
      Organization {
        id
        name
        isVip
        departments {
          id
          name
          postCode
          town
          street
          buildingNumber
          localNumber
          devices {
            id
          }
        }
      }
      user {
        id
        name
        surname
        email
        mobile
        phone
        avatar
      }
      Department {
        id
        name
      }
    }
  }
`;

export const LOAD_CLIENT_PERSON_SETTLEMENTS = gql`
  query LoadClientPerson($id: ID!) {
    clientPerson(id: $id) {
      id
      tickets {
        settlements {
          id
        }
      }
    }
  }
`;
export const LOAD_CLIENT_DEVICES = gql`
  query LoadClientDevices($id: ID!) {
    clientPerson(id: $id) {
      id
      Organization {
        id
        departments {
          id
          name
          postCode
          town
          street
          buildingNumber
          localNumber
          devices {
            id
          }
        }
      }
      Department {
        id
        name
      }
      devices {
        _id
        id
        serialNumber
        isActive
        Department {
          id
          name
          organization {
            id
            name
          }
        }
        warehausProduct {
          id
          model
          isColour
          avatar
          manufacturer {
            id
            name
          }
        }
        clientPerson {
          id
        }
        tickets {
          id
          status
          label
        }
        agreements {
          type
          offer
        }
      }
    }
  }
`;
export const LOAD_TICKETS = gql`
  query {
    tickets {
      _id
      id
      name
      title
      label
      status
      settlements {
        id
      }
      device {
        id
        serialNumber
        warehausProduct {
          id
          model
          manufacturer {
            id
            name
          }
        }
        Department {
          id
          name
          organization {
            id
            name
            recName
          }
        }
      }
      clientPerson {
        id
        user {
          id
          name
          avatar
        }
      }
      tmbPerson {
        id
        user {
          id
          name
          avatar
          surname
        }
      }
      createdAt
    }
  }
`;
export const LOAD_TICKET = gql`
  query LoadTicket($id: ID!) {
    ticket(id: $id) {
      _id
      id
      title
      label
      status
      createdAt

      author {
        id
        name
        surname
      }
      clientPerson {
        id
        user {
          id
          name
          surname
          avatar
        }
      }
      tmbPerson {
        id
        user {
          id
          name
          avatar
          surname
        }
      }
      device {
        id
        serialNumber
        agreements {
          id
          type
          label
          offer
        }
        warehausProduct {
          id
          model
          manufacturer {
            id
            name
          }
        }
        Department {
          id
          name
          organization {
            id
            name
          }
        }
      }
      activityLog {
        id
        createdAt
        tmbPerson {
          id
          user {
            id
            name
            avatar
            surname
          }
        }
        clientPerson {
          id
          user {
            id
            name
            avatar
            surname
          }
        }
        note {
          id
          description
          attachments {
            id
            title
            file
          }
          links {
            id
            title
            linkAddress
          }
        }
        link {
          id
          linkAddress
          title
        }
        attachment {
          id
          file
          title
        }
        agreement {
          id
          label
          status
          type
          isFromServiceVisit
        }
        serviceVisitId
      }
      serviceVisits {
        id
        status

        name
      }
    }
  }
`;
export const LOAD_TICKET_SETTLEMENTS = gql`
  query LoadTicket($id: ID!) {
    ticket(id: $id) {
      _id
      id
      settlements {
        createdAt
        name
        id
      }
      device {
        id
        warehausProduct {
          model
        }
        Department {
          organization {
            id
            name
          }
        }
      }
    }
  }
`;
export const LOAD_TICKET_VISITS = gql`
  query LoadTicket($id: ID!) {
    ticket(id: $id) {
      id
      _id
      serviceVisits {
        id
        _id
        name
        description
        isPiorityVisit
        datetimeStart
        timeDuration
        status
        travelTimeBefore
        travelTimeAfter
        isPiorityVisit
        activities
        ticket {
          label
        }
        device {
          id
        }
      }
    }
  }
`;
export const LOAD_PRODUCTS = gql`
  query {
    warehausProducts {
      id
      model
      symbol
      avatar
      isColour
      quantity
      familyTypes {
        id
        name
      }
      productType {
        name
      }
      deviceType {
        name
      }
      manufacturer {
        id
        name
      }
      description
    }
  }
`;
export const LOAD_PRODUCT = gql`
  query LoadProduct($id: ID!) {
    warehausProduct(id: $id) {
      id
      model
      symbol
      avatar
      isColour
      priceNettoA
      priceNettoB
      priceNettoC
      familyTypes {
        id
        name
      }
      manufacturer {
        id
        name
      }
      description
      quantity
    }
  }
`;
export const LOAD_DEVICE_ACTIVITIES = gql`
  query LoadDevicesActivities($id: ID!) {
    device(id: $id) {
      id
      activityLog {
        id
        attachment {
          id
          file
          title
        }
        link {
          id
          title
          linkAddress
        }
      }
    }
  }
`;
export const LOAD_WAREHAUSES = gql`
  query {
    warehauses {
      id
      symbol
      name
      status
    }
  }
`;
export const LOAD_SERVICE_TOPICS = gql`
  query {
    serviceTitles {
      id
      type
      title
      tmbVisible
      clientVisible
    }
  }
`;
export const LOAD_SERVICE_TOPIC = gql`
  query LoadServiceTopics($id: ID!) {
    serviceTitle(id: $id) {
      id
      title
      tmbVisible
      clientVisible
    }
  }
`;
export const LOAD_AGREEMENTS = gql`
  query {
    agreements {
      id
      name
      type
      offer
      label
      device {
        id
        deviceHistories {
          createdAt
          device {
            id
          }
        }
        Department {
          organization {
            id
            name
          }
        }
        warehausProduct {
          model
        }
      }
      status
      bwPagePack
      costPageBw
      attachment
      scanPagePack
      costPageScan
      colorPagePack
      costPageColor
      subscriptionFee
      AgreementStartDate
      AgreementExpirationDate
      createdAt
      updatedAt
      warehouseProductCopy
    }
  }
`;

export const LOAD_AGREEMENTS_SETTLE = gql`
  query {
    agreements {
      id
      name
      type
      device {
        id
        deviceHistories {
          createdAt
        }
        Department {
          organization {
            id
            name
          }
        }
        warehausProduct {
          model
        }
      }

      AgreementStartDate
      AgreementExpirationDate
      createdAt
      updatedAt
    }
  }
`;

export const LOAD_AGREEMENTS_TABLE = gql`
  query {
    agreements {
      id
      name
      type
      device {
        id
        warehausProduct {
          model
        }
      }
    }
  }
`;

export const LOAD_AGREEMENT = gql`
  query LoadAgreement($id: ID!) {
    agreement(id: $id) {
      id
      type
      label
      device {
        id
        Department {
          organization {
            id
            name
          }
        }
        warehausProduct {
          id
          model
        }
      }
      settlements {
        id
        file
      }
      offer
      status
      bwPagePack
      costPageBw
      attachment
      scanPagePack
      costPageScan
      colorPagePack
      costPageColor
      subscriptionFee
      AgreementStartDate
      AgreementExpirationDate
      warehouseProductCopy
      isCostOverrun
      activityLog {
        id
        tmbPerson {
          user {
            name
            surname
          }
        }
        clientPerson {
          user {
            name
            surname
          }
        }
        agreementLabel
        createdAt
      }
    }
  }
`;
export const LOAD_AGREEMENT_USERS = gql`
  query LoadAgreement($id: ID!) {
    agreement(id: $id) {
      device {
        id
        Department {
          organization {
            clientPeople {
              user {
                name
                surname
              }
            }
          }
        }
      }
    }
  }
`;
export const LOAD_OFFER_FILTERS = gql`
  query LoadOfferFilters(
    $manufacturer: String!
    $productType: String!
    $familyTypes: String!
    $model: String!
  ) {
    warehausProducts(
      manufacturer: $manufacturer
      productType: $productType
      familyTypes: $familyTypes
      model: $model
    ) {
      id
      model
      priceNettoC
      leadTime
      quantity
      productType {
        name
      }
    }
    manufacturers {
      id
      name
    }
    productTypes {
      id
      name
    }
    familyTypes {
      id
      name
    }
  }
`;
export const LOAD_OFFERS = gql`
  query LoadOffers {
    agreements {
      id
      label
      offer
      name
      status
      isFromServiceVisit
      AgreementExpirationDate
      device {
        id
        serialNumber
        warehausProduct {
          id
          model
        }
        Department {
          organization {
            id
            name
            recName
          }
        }
      }
      type
      crmEvents {
        id
      }
    }
  }
`;
export const LOAD_CLIENT_PERSONS = gql`
  query LoadClientPersons {
    clientPeople {
      id
      position
      Department {
        id
        name
        organization {
          id
          name
        }
      }
      devices {
        id
      }
      user {
        id
        name
        surname
        email
        phone
        mobile
      }
    }
  }
`;
export const LOAD_CLIENT_PERSONS_EMAILS = gql`
  query LoadClientPersons {
    clientPeople {
      user {
        email
      }
    }
  }
`;

export const LOAD_TMB_PERSONS_EMAILS = gql`
  query LoadTmbPersons {
    tmbPeople {
      user {
        email
      }
    }
  }
`;

export const LOAD_USERS_EMAILS = gql`
  query {
    users {
      email
    }
  }
`;

export const LOAD_TMB_PERSONS = gql`
  query LoadTmbPersons {
    tmbPeople {
      id
      user {
        id
        name
        surname
        email
        roles
        phone
        mobile
        symbol
        website
      }
    }
  }
`;
export const LOAD_TMB_PERSON = gql`
  query LoadTmbPerson($id: ID!) {
    tmbPerson(id: $id) {
      id
      colour
      user {
        id
        name
        surname
        email
        roles
        phone
        mobile
        symbol
        website
        avatar
      }
    }
  }
`;
export const LOAD_CRMS = gql`
  query LoadCrms {
    crms {
      id
      name
      organization {
        id
        name
      }
      clientPerson {
        id
        user {
          name
          surname
        }
      }
      CrmStatusWin
      CrmStatusWorkflow
      activityLogs {
        id
        tmbPerson {
          id
        }
        createdAt
        crmEvent {
          id
          type
          note
          phone
          email
          Agreement {
            id
          }
          dateExecution
          datePlanning
        }
      }
    }
  }
`;
export const LOAD_CRM = gql`
  query LoadCrm($id: ID!) {
    crm(id: $id) {
      id
      name
      organization {
        id
        name
      }
      clientPerson {
        id
        user {
          name
          surname
        }
      }
      CrmStatusWin
      CrmStatusWorkflow
      activityLogs {
        id
        tmbPerson {
          id
        }
        createdAt
        agreement {
          id
        }
        crmEvent {
          id
          type
          note
          phone
          email
          Agreement {
            id
          }
          dateExecution
          datePlanning
        }
      }
    }
  }
`;
export const LOAD_CRM_ACTIVITY_LOG = gql`
  query LoadCrmActivityLog($id: ID!) {
    activityLog(id: $id) {
      id
      crmEvent {
        id
        datePlanning
        type
        note
      }
    }
  }
`;
export const LOAD_SERVICE_VISITS = gql`
  query LoadServiceVisits {
    serviceVisits {
      id
      name
      isPiorityVisit
      ticket {
        id
        _id
        status
        device {
          id
          Department {
            id
            postCode
            organization {
              id
              hasRecipient
              recPostCode
              isVip
            }
          }
        }
      }
      tmbPerson {
        id
        colour
        user {
          name
          surname
        }
      }
      datetimeStart
      status
      travelTimeBefore
      travelTimeAfter
      isPiorityVisit
      activities
      device {
        id
        Department {
          id
          postCode
          organization {
            id
            hasRecipient
            recPostCode
            isVip
          }
        }
      }
    }
  }
`;
export const LOAD_SERVICE_VISIT = gql`
  query LoadServiceVisit($id: ID!) {
    serviceVisit(id: $id) {
      id
      name
      description
      isPiorityVisit
      ticket {
        id
        status
        device {
          id
          serialNumber
          Department {
            id
            name
            organization {
              id
              name
            }
          }
        }
      }
      tmbPerson {
        id
        user {
          name
          surname
        }
      }
      datetimeStart
      status
      travelTimeBefore
      travelTimeAfter
      isPiorityVisit
      activities
      device {
        id
        serialNumber
        Department {
          id
          name
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

export const LOAD_SERVICE_VISIT_STATUS = gql`
  query LoadServiceVisit($id: ID!) {
    serviceVisit(id: $id) {
      id
      status
    }
  }
`;

export const LOAD_SERVICE_VISIT_POPUP = gql`
  query LoadServiceVisit($id: ID!) {
    serviceVisit(id: $id) {
      description
      name
      ticket {
        title
      }
      device {
        _id
      }
    }
  }
`;

export const LOAD_DEVICE_POPUP = gql`
  query LoadDevice($id: ID!) {
    device(id: $id) {
      serialNumber
      warehausProduct {
        model
      }
    }
  }
`;

export const LOAD_REGIONS = gql`
  query LoadedRegions {
    regions {
      id
      name
      postalCodes
    }
  }
`;
export const LOAD_REGION = gql`
  query LoadedRegion($id: ID!) {
    region(id: $id) {
      id
      name
      postalCodes
    }
  }
`;
export const LOAD_TICKET_AGREEMENTS = gql`
  query LoadedTicketAgreements($id: ID!) {
    ticket(id: $id) {
      id
      activityLog {
        id
        agreement {
          id
          type
          warehouseProductCopy
          status
          label
        }
      }
    }
  }
`;

export const LOAD_SETTLEMENTS = gql`
  query LoadedSettlements {
    settlements {
      id
      name
      ticket {
        id
        device {
          id
          Department {
            organization {
              id
              name
            }
          }
          warehausProduct {
            id
            model
          }
        }
      }
      file
      createdAt
      warehausProductCopy
    }
  }
`;
export const LOAD_SETTLEMENT = gql`
  query LoadedSettlements($id: ID!) {
    settlement(id: $id) {
      id
      name
      ticket {
        id
      }
      file
      createdAt
      warehausProductCopy
    }
  }
`;
export const LOAD_ORGANIZATION_CLIENT_PERSONS = gql`
  query LoadedClientPersons($organization_id: String!) {
    clientPeople(Organization: $organization_id) {
      id
      user {
        id
        name
        surname
      }
    }
  }
`;
