import moment from 'moment';
import { agreementTypes, settings } from '../config';
import validator from 'validator';
export const isIterableArray = (array) => Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
  JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
  parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2));

//===============================
// Cookie
//===============================
export const getCookieValue = (name) => {
  const value = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
  if (!moment.isMoment(startDate))
    throw new Error(`Start date must be a moment object, received ${typeof startDate}`);
  if (endDate && !moment.isMoment(endDate))
    throw new Error(`End date must be a moment object, received ${typeof startDate}`);

  return `${startDate.format('ll')} - ${
    endDate ? endDate.format('ll') : 'Present'
  } • ${startDate.from(endDate || moment(), true)}`;
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = (hexValue) => {
  let hex;
  hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

export const getGrays = (isDark) => (isDark ? darkGrays : grays);

export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) => (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find((item) => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);

//===============================
// Date formatters
//===============================
export const formatMessageDate = (value) => {
  let day = '';
  switch (moment(value).format('dddd')) {
    case 'Monday':
      day = 'Pn';
      break;
    case 'Tuesday':
      day = 'Wt';
      break;
    case 'Wednesday':
      day = 'Śr';
      break;
    case 'Thursday':
      day = 'Cz';
      break;
    case 'Friday':
      day = 'Pt';
      break;
    case 'Saturday':
      day = 'Sb';
      break;
    case 'Sunday':
      day = 'Nd';
      break;
    default:
      day = '';
  }
  const hour = moment(value).format('HH:mm');
  const number = moment(value).format('DD');
  const year = moment(value).format('YYYY');
  const date = `${day} ${number}, ${year}`;
  return { day, hour, date };
};

export const formatDateTime = (date) => {
  return moment(date).format(settings.dateTimeFormat);
};

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = (str) => (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map((route) => {
    if (route.children) {
      return route.children.map((item) => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = (pageName) => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = (textFieldRef) => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

export const sortObjectsArray = (array, property) => {
  return array.sort((a, b) => (a[property] > b[property] ? -1 : 1));
};

export const sortCrmEvents = (array) => {
  const doneArray = [...array.filter((obj) => (obj.dateExecution ? true : false))];
  const toDoArray = [...array.filter((obj) => (obj.dateExecution ? false : true))];
  return [
    ...sortObjectsArray(toDoArray, 'dateCreated'),
    ...sortObjectsArray(doneArray, 'dateCreated')
  ];
};

//===============================
// Validators
//===============================
export const validateFileFormat = (file) => {
  let valid = false;
  if (
    file.name.includes('.pdf') ||
    file.name.includes('.jpg') ||
    file.name.includes('.jpeg') ||
    file.name.includes('.png') ||
    file.name.includes('.zip') ||
    file.name.includes('.rar')
  )
    valid = true;
  return valid;
};

export const validateUserForm = (form, setPassword, setFields) => {
  const { name, surname, email, password, repPassword } = form;
  const passwordValid =
    password === repPassword ||
    ((password?.length === 0 || password === undefined) &&
      (repPassword?.length === 0 || repPassword === undefined));
  const fieldsValid = name?.trim() && surname?.trim() && validator.isEmail(email);
  let fieldsValidTwo;
  if (!isNaN(form?.department?.length) && !isNaN(form?.organization?.length)) {
    fieldsValidTwo = form.department.length > 0 && form.organization.length > 0;
  } else {
    fieldsValidTwo = true;
  }
  setPassword(passwordValid);
  setFields(fieldsValid && fieldsValidTwo);
  return passwordValid && fieldsValid && fieldsValidTwo;
};

//===============================
// Formatters
//===============================
export const truncate = (input) => (input.length > 40 ? `${input.substring(0, 40)}...` : input);

export const getOfferName = (type) => {
  switch (type) {
    case agreementTypes.tradeRental:
      return 'Oferta wynajmu';
    case agreementTypes.tradeService:
      return 'Oferta obsługi';
    case agreementTypes.tradeServiceRental:
      return 'Oferta serwisowa';
    case agreementTypes.service:
      return 'Oferta naprawy serwisowej';
    default:
      return '--';
  }
};
export const getAgreementName = (type) => {
  switch (type) {
    case agreementTypes.tradeRental:
      return 'Umowa wynajmu';
    case agreementTypes.tradeService:
      return 'Umowa obsługi';
    case agreementTypes.tradeServiceRental:
      return 'Umowa serwisowa';
    case agreementTypes.service:
      return 'Umowa naprawy serwisowej';
    default:
      return '--';
  }
};

export const reduceId = (id) => {
  const longId = String(id);
  const collections = [
    '/api/activity_logs/',
    '/api/agreements/',
    '/api/attachments/',
    '/api/client_people/',
    '/api/contracts/',
    '/api/deals/',
    '/api/departments/',
    '/api/device_histories/',
    '/api/device_types/',
    '/api/devices/',
    '/api/family_types/',
    '/api/links/',
    '/api/manufacturers/',
    '/api/notes/',
    '/api/offers/',
    '/api/organizations/',
    '/api/product_types/',
    '/api/service_titles/',
    '/api/tickets/',
    '/api/tmb_people/',
    '/api/users/',
    '/api/warehaus_products/',
    '/api/warehauses/',
    '/api/crms/',
    '/api/settlements/',
    '/api/service_visits/'
  ];
  let result = null;
  collections.map((el) => (longId.includes(el) ? (result = Number(longId.replace(el, ''))) : null));
  return result;
};

export const formatCurrency = (value) => {
  // Returns  x.xx zł
  if (value === undefined || value === null) return '--';
  return `${parseFloat(value).toFixed(2)} ${settings.currency}`;
};

export const formatCurrencyBrutto = (value) => {
  // Returns  x.xx zł + 23%
  return `${(parseFloat(value) + (parseFloat(value) * 23) / 100).toFixed(2)} zł`;
};

export const dotToComma = (str) => str.replace('.', ',');

export const crmTypeToName = (type) => {
  switch (type) {
    case 'note':
      return 'Notatka';
    case 'phone':
      return 'Telefon';
    case 'meeting':
      return 'Spotkanie';
    case 'email':
      return 'E-mail';
    case 'offer':
      return 'Oferta';
    default:
      return 'Zdarzenie';
  }
};
export const crmTypeToIcon = (type) => {
  switch (type) {
    case 'note':
      return 'sticky-note';
    case 'phone':
      return 'phone';
    case 'meeting':
      return 'handshake';
    case 'email':
      return 'envelope';
    default:
      return 'calendar-check';
  }
};
export const crmStatusDecode = (status) => {
  switch (status) {
    case 'idea':
      return 'Pomysł';
    case 'contact':
      return 'Kontakt';
    case 'needs':
      return 'Potrzeby zidentyfikowane';
    case 'sent':
      return 'Oferta wysłana';
    case 'negotiations':
      return 'Negocjacje';
    case 'open':
      return 'Otwarta';
    case 'win':
      return 'Wygrana';
    case 'loss':
      return 'Przegrana';
    default:
      return '--';
  }
};

//===============================
// Planner new event start date generator
//===============================
export const getPlannerStartDate = (selectDate, visits) => {
  const dateFitVisits = [];
  let latestDate = null;

  visits.map((visit) =>
    visit.status === 'planned' &&
    moment(visit.start).format('DD-MM-YYYY') === moment(selectDate).format('DD-MM-YYYY')
      ? dateFitVisits.push(visit.start)
      : null
  );
  console.log(dateFitVisits);
  if (dateFitVisits.length > 0)
    dateFitVisits.forEach((date) => {
      if (!latestDate || moment(latestDate).format() < moment(date).format()) latestDate = date;
    });
  else
    return moment(selectDate)
      .set({ h: 0, m: 0 })
      .format();

  return moment(latestDate)
    .add(1, 'hours')
    .format();
};

//===============================
// Planner check if region includes visit
//===============================
export const isRegionIncludesVisit = (postalCodes, ticket) => {
  console.log('Start');
  let includes = false;
  let visitCode = undefined;
  console.log(ticket);
  try {
    let hasRecipient = ticket.device.Department.organization.hasRecipient || false;
    if (hasRecipient) visitCode = ticket.device.Department.organization.recPostCode;
    else visitCode = ticket.device.Department.postCode;
  } catch {
    console.error('Failed checking region filter');
  }
  if (visitCode && postalCodes.includes(visitCode)) includes = true;

  console.log(includes);

  return includes;
};

//===============================
// Agreement type to name
//===============================
export const agreementTypeToName = (value, type) => {
  switch (value) {
    case 0:
      return type === 'offer' ? 'Oferta wynajmu' : 'Umowa wynajmu';
    case 1:
      return type === 'offer' ? 'Oferta obsługi' : 'Umowa obsługi';
    case 2:
      return type === 'offer' ? 'Oferta serwisowa' : 'Umowa serwisowa';
    case 3:
      return type === 'offer' ? 'Oferta naprawy serwisowej' : 'Umowa naprawy serwisowej';
    default:
      return '--';
  }
};

//===============================
// Offer status name
//===============================
export const getOfferStatus = (status) => {
  if (status === 'active') return 'Aktywna';
  if (status === 'inactive') return 'Niektywna';
  if (status === 'sent') return 'Wysłana';
  else return '--';
};

//===============================
// calculate maximum available product count
//===============================

export const countMaxQuantity = (warehausProductQuantity) => {
  let quantity = 0;
  warehausProductQuantity.forEach((el) => {
    quantity += parseInt(el.quantity);
  });
  return quantity;
};

export const getExtendedProductData = (products, currentProducts) => {
  const extendedCurrentProductArray = [];
  products.forEach(({ id, isService, model, priceNettoC, quantity, warehouses }) => {
    currentProducts.forEach((current) => {
      if (current.id === reduceId(id)) {
        const maxQuantity = countMaxQuantity(warehouses);
        extendedCurrentProductArray.push({
          leadTime: current.leadTime,
          id: current.id,
          quantity: current.quantity,
          maxQuantity: maxQuantity === 0 ? current.quantity : maxQuantity,
          price: current.price,
          isService,
          model,
          priceNettoC,
          warehouses
        });
      }
    });
  });
  return extendedCurrentProductArray;
};

export const getAgreementSettlementData = (dataArray) => {
  const agreementSettlementData = [];
  const monthsArray = [];

  const isAgreemenSettled = (monthDetailsArr) => {
    let successDate = '';
    let failureDate = '';
    let settled = false;
    let fv = null;
    monthDetailsArr.forEach(({ settledCorrectly, date, file }) => {
      if (settledCorrectly) {
        settled = true;
        successDate = date;
        fv = file;
      }
      failureDate = date;
    });
    return {
      settlementDate: settled ? successDate : failureDate,
      settledCorrectly: settled,
      fv
    };
  };
  //setup array with months:
  dataArray.forEach(({ created_at, updated_at }) => {
    const date = updated_at ? updated_at.date : created_at.date;
    if (!monthsArray.includes(moment(date).format(settings.monthFormat))) {
      monthsArray.push(moment(date).format(settings.monthFormat));
    }
  });
  //loop thorugh months and format settlement data:
  monthsArray.forEach((month, i) => {
    const monthDetailsArr = [];
    dataArray.forEach((el, j) => {
      const date = el.updated_at ? el.updated_at.date : el.created_at.date;
      if (month === moment(date).format(settings.monthFormat)) {
        monthDetailsArr.push({
          id: j + 1,
          date: el.updated_at.date,
          settledCorrectly: el.success,
          status: el.errors,
          file: el.file
        });
      }
    });
    const { settledCorrectly, settlementDate, fv } = isAgreemenSettled(monthDetailsArr);
    agreementSettlementData.push({
      id: i + 1,
      month,
      settlementDate,
      fv,
      settledCorrectly,
      details: [...monthDetailsArr]
    });
  });
  return agreementSettlementData;
};

export const visitStatusFormatter = (status) => {
  switch (status) {
    case 'planning':
      return 'Serwis do zaplanowania';
    case 'planned':
      return 'Serwis zaplanowany';
    case 'service_visit_during':
      return 'Serwis w trakcie';
    case 'done':
      return 'Serwis zakończony';
    case 'client_accept':
      return 'Akceptacja klienta';
    case 'client_rejection':
      return 'Odrzucenie klienta';
    case 'settled':
      return 'Rozliczona';
    default:
      return '--';
  }
};
