const userReducer = (state = false, action) => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        isAuth: true,
        ...action.payload
      };
    case 'SIGN_OUT':
      return {
        isAuth: false
      };
    default:
      return state;
  }
};

export default userReducer;
